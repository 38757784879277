<script setup>
import PageTitle from '@/components/PageTitle.vue'
import ColumnData from '@/components/ColumnData.vue'
import GrayBarData from '@/components/GrayBarData.vue'
import { defineProps } from 'vue'

defineProps({
  pdfLayout: Boolean
})

const vehicleTrafficData = {
  streetTraffic: (num) => { return num.toLocaleString() },
  vehiclesPerHousehold: 1.97,
  vehiclesInTradeArea: (num) => { return num.toLocaleString() },
  medianCommuteTime: 28.7,
  roadType: 'Single Carriageway',
  trafficFlow: 'Two Way Traffic',
  lengthOfRoad: 102.5,
  speedLimit: 35
}
</script>

<template>
      <PageTitle :icon="require('../../assets/images/vehicle-data-icon.png')" title="Vehicle and Traffic Data" subtitle="The number of cars in the trade area, and specifically the number of cars driving by the site are important because those are potential customers." :pdf-layout=pdfLayout />
      <GrayBarData :data="vehicleTrafficData" class="component-margin-y">
        <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="vehicleTrafficData.streetTraffic(132837)" description="Street Traffic (Cars)*" />
        <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="vehicleTrafficData.vehiclesPerHousehold" description="Vehicles Per Household" />
        <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="vehicleTrafficData.vehiclesInTradeArea(68503)" description="Vehicles in Trade Area*" />
      </GrayBarData>
      <section class="flex component-margin-y text-xl justify-content-between">
          <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-3" :data="vehicleTrafficData.medianCommuteTime" description="Median Commute Time (min)" />
          <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-4" :data="vehicleTrafficData.roadType" description="Road Type*" />
          <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-3" :data="vehicleTrafficData.trafficFlow" description="Traffic Flow*" />
      </section>
      <section class="flex component-margin-y text-xl justify-content-between">
          <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-3" :data="vehicleTrafficData.lengthOfRoad" description="Length of Road Segment" />
          <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-4" :data="vehicleTrafficData.medianCommuteTime" description="Median Commute Time (min)" />
          <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-3" :data="vehicleTrafficData.speedLimit" description="Speed Limit (mph)" />
      </section>
      <section class="flex justify-content-center component-margin-y">
        <img src="../../assets/images/map.png" alt="Map Placeholder" class="w-full">
      </section>
</template>

<style scoped>
</style>
