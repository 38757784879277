<script setup>
import PageTitle from '@/components/PageTitle.vue'
import { defineProps } from 'vue'
import GrayBarData from '@/components/GrayBarData.vue'
import ColumnData from '@/components/ColumnData.vue'

defineProps({
  pdfLayout: Boolean
})

const tradeAreaData = {
  tradeAreaPopulation: (num) => { return num.toLocaleString() },
  populationDensity: 1.97,
  addressableMarket: (num) => { return num.toLocaleString() },
  targetMarket: (num) => { return num.toLocaleString() },
  totalDrivingAgePopulation: (num) => { return num.toLocaleString() },
  drivingAgePopulationSegment: 84.2,
  averageAge: 41.4,
  targetMarketSegment: 26.7
}

</script>

<template>
  <div>
    <PageTitle :subPage="true" title="Trade Area Overview" subtitle="The trade area is the area traveled by the vehicles that pass the site during any given time period. A trade area will generally follow highly travelled roads and areas that people visit frequently. The trade area overview looks at the basic demographics of the people who visit this trade area." :pdf-layout=pdfLayout  />
    <img src="../../assets/images/trade-area-map-placeholder.png" alt="Trade Area Map Placeholder" class="w-full component-margin-y">
  </div>
  <GrayBarData :data="tradeAreaData" class="mt-6">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.tradeAreaPopulation(237011)" description="Trade Area Population*" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.populationDensity" description="Population Density (Per Square Mile)" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.addressableMarket(165490)" description="Addressable Market (Age 22-65)" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.targetMarket(49007)" description="Target Market (Age 35-55)" />
  </GrayBarData>
  <GrayBarData :data="tradeAreaData">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.totalDrivingAgePopulation(149215)" description="Total Driving Age Population" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.drivingAgePopulationSegment + '%'" description="Driving Age Population Segment" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.averageAge" description="Average Age" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-2" :data="tradeAreaData.targetMarketSegment + '%'" description="Target Market Segment (35-55)" />
  </GrayBarData>
</template>

<style scoped>

</style>
