<script setup>
import PageTitle from '@/components/PageTitle.vue'
import AppDataTable from '@/components/AppDataTable.vue'
import * as pattern from 'patternomaly'
import BatteryChartComponent from '@/components/BatteryChartComponent.vue'
import { defineProps } from 'vue'

defineProps({
  pdfLayout: Boolean
})

const locationTable = {
  tableColumns: [
    {
      field: 'commute',
      header: 'Commute Time'
    },
    {
      field: 'population',
      header: 'Population',
      style: 'column-right-align'
    },
    {
      field: 'percentage',
      header: 'Percentage',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      commute: 'Less Than 10 Minutes',
      population: '6,620',
      percentage: '6.7%'
    },
    {
      commute: '10-15 minutes',
      population: '8,092',
      percentage: '7.1%'
    },
    {
      commute: '15-20 minutes',
      population: '10,449',
      percentage: '15.1%'
    },
    {
      commute: '20-25 minutes',
      population: '11,696',
      percentage: '17.3%'
    },
    {
      commute: '25-30 minutes',
      population: '5,670',
      percentage: '6.4%'
    },
    {
      commute: '30-35 minutes',
      population: '16,208',
      percentage: '20.7%'
    },
    {
      commute: '35-45 minutes',
      population: '7,320',
      percentage: '7.5%'
    },
    {
      commute: '45-60 minutes',
      population: '9,154',
      percentage: '9.2%'
    },
    {
      commute: 'An hour or more',
      population: '8,997',
      percentage: '8.9%'
    }
  ]
}
const batteryChart = {
  title: '',
  height: 60,
  data: {
    labels: [''],
    datasets: [
      {
        type: 'bar',
        label: 'Drove to Work',
        backgroundColor: pattern.draw('diagonal', '#1be1f2'),
        borderSkipped: false,
        datalabels: {
          color: '#000'
        },
        data: [12]
      },
      {
        type: 'bar',
        label: 'Drove Alone',
        backgroundColor: '#00263A',
        datalabels: {
          color: '#FFF'
        },
        data: [30.8]
      },
      {
        type: 'bar',
        label: 'Carpooled',
        backgroundColor: '#494949',
        datalabels: {
          color: '#FFF'
        },
        data: [20.8]
      },
      {
        type: 'bar',
        label: 'Public Transit',
        backgroundColor: '#767676',
        datalabels: {
          color: '#FFF'
        },
        data: [18.2]
      },
      {
        type: 'bar',
        label: 'Other (Walk, Bike)',
        backgroundColor: '#D9D9D9',
        datalabels: {
          color: '#000'
        },
        data: [18.2]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    borderColor: '#000',
    borderWidth: 1,
    layout: {
      padding: {
        bottom: 60
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: 'black',
            clamp: 'center',
            anchor: 'center',
            align: 'bottom',
            offset: 60,
            rotation: -25,
            font: { size: 14 },
            formatter: function (value, context) {
              return context.chart.data.datasets[context.datasetIndex].label
            }
          },
          value: {
            anchor: 'center',
            align: 'bottom',
            offset: 15,
            font: {
              weight: 'bold',
              size: 20
            },
            formatter: function (value) {
              return `${value}%`
            }
          }
        }
      }
    },
    style: {
      height: 100,
      width: 100
    },
    scales: {
      x: {
        display: false,
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  }
}
const batteryChartPDF = {
  title: '',
  height: 60,
  data: {
    labels: [''],
    datasets: [
      {
        type: 'bar',
        label: 'Drove to Work',
        backgroundColor: pattern.draw('diagonal', '#1be1f2'),
        borderSkipped: false,
        datalabels: {
          color: '#000'
        },
        data: [12]
      },
      {
        type: 'bar',
        label: 'Drove Alone',
        backgroundColor: '#00263A',
        datalabels: {
          color: '#FFF'
        },
        data: [30.8]
      },
      {
        type: 'bar',
        label: 'Carpooled',
        backgroundColor: '#494949',
        datalabels: {
          color: '#FFF'
        },
        data: [20.8]
      },
      {
        type: 'bar',
        label: 'Public Transit',
        backgroundColor: '#767676',
        datalabels: {
          color: '#FFF'
        },
        data: [18.2]
      },
      {
        type: 'bar',
        label: 'Other (Walk, Bike)',
        backgroundColor: '#D9D9D9',
        datalabels: {
          color: '#000'
        },
        data: [18.2]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    borderColor: '#000',
    borderWidth: 1,
    layout: {
      padding: {
        bottom: 60
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            display: false,
            formatter: function (value, context) {
              return context.chart.data.datasets[context.datasetIndex].label
            }
          },
          value: {
            display: false,
            anchor: 'center',
            align: 'bottom',
            offset: 15,
            font: {
              weight: 'bold',
              size: 20
            },
            formatter: function (value) {
              return `${value}%`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: false,
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  }
}
const batteryChartLabelColors = ['#00263A', '#FFF', '#FFF', '#FFF', '#000', '#000', '#000']

const style = (i) => {
  return { width: `${batteryChartPDF.data.datasets[i].data}vw`, color: `${batteryChartLabelColors[i]}` }
}

const labelStyle = (i) => {
  return { width: `${batteryChartPDF.data.datasets[i].data}vw` }
}
</script>

<template>
  <PageTitle :sub-page="true" title="Vehicle Journey Data" subtitle="The most common driving path for most people is between their home and their workplace. Understanding how long these commutes are and where they travel can be beneficial in determining how to attract these customers to the site." />
  <section class="component-margin-y">
    <h2 class="chart-title">
      Commute Time to Work
    </h2>
    <app-data-table
      :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }" :columns="locationTable.tableColumns"
      :title="locationTable.title"
      :value="locationTable.tableData"
    />
  </section>
  <section v-if="pdfLayout">
    <h2 class="chart-title">Transportation to Work</h2>
    <BatteryChartComponent :pdfLayout="pdfLayout" v-bind="{...batteryChartPDF}" :height="70" title=""/>
    <div class="flex">
      <span
        v-for="(value, i) in batteryChartPDF.data.datasets"
        class="battery-values-pdf font-suds-medium"
        :key="i"
        :style="style(i)"
      >
        {{ value.data[0] }}%
      </span>
        </div>
        <div class="flex">
      <span
        v-for="(value, i) in batteryChartPDF.data.datasets"
        class="battery-label-pdf font-suds-medium"
        :key="i"
        :style="labelStyle(i)"
      >
        {{ value.label }}
      </span>
    </div>
  </section>
  <section v-else class="component-margin-y">
    <h2>Transportation to Work</h2>
    <BatteryChartComponent v-bind="{...batteryChart}" :height="55" :pdfLayout="pdfLayout" title=""/>
  </section>
  <section class="flex justify-content-center component-margin-y">
    <img src="../../assets/images/google-map-placeholder.png" alt="Google Map Placeholder" class="w-full h-20rem">
  </section>
</template>

<style scoped>
.battery-values-pdf{
  text-align: center;
  margin-top: -110px;
  position: relative;
  z-index: 9999;
}
.battery-label-pdf{
  text-align: center;
  transform: rotate(-25deg); /* Forced to do transform here with html2pdf constraints */
  margin-top: -45px;
  font-size: small;
}
</style>
