<script setup>
import BarChartComponent from '@/components/BarChartComponent.vue'
import PieChartComponent from '@/components/PieChartComponent.vue'
import GrayBarData from '@/components/GrayBarData'
import PageTitle from '@/components/PageTitle.vue'
import { defineProps, ref } from 'vue'
import ColumnData from '@/components/ColumnData.vue'

defineProps({
  pdfLayout: Boolean
})

const householdOverviewData = ref(null)
const getHouseholdOverviewData = async () => {
  await new Promise((resolve) => setTimeout(resolve, 5000))

  householdOverviewData.value = {
    medianIncome: '$95,809.05',
    medianPropertyValue: '$746,477',
    households: '67,430',
    disposableIncome: '$82,057.17',
    personsPerHouse: '2.56',
    householdsWithChildren: '3,366',
    area: '7.80'
  }
}
await getHouseholdOverviewData()

const householdStatusPie = {
  title: '',
  datalabels: {
    color: ['#FFF', '#000']
  },
  data: {
    labels: ['Single Owner', 'Joint Household'],
    datasets: [
      {
        data: [25.1, 74.9],
        backgroundColor: ['#00263A', '#45C3D2']
      }
    ]
  },
  options: {
    borderWidth: 0,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'rect',
          font: {
            size: 16
          }
        }
      },
      datalabels: {
        display: true,
        color: ['#FFF', '#000'],
        font: {
          size: 18,
          weight: 'bold'
        },
        formatter: function (value) {
          return `${value}%`
        }
      }
    }
  }
}
const vehiclesPerHouseChart = {
  title: '',
  data: {
    labels: ['No Vehicles', '1 Vehicle', '2 Vehicles', '3+ Vehicles'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [2476, 21854, 28084, 17309]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 100,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -100,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Households',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false
        },
        beginAtZero: true,
        afterBuildTicks: axis => {
          axis.ticks = [
            { value: 0 },
            { value: 10000 },
            { value: 20000 },
            { value: 30000 }
          ]
        },
        ticks: {
          stepSize: 1000,
          callback: (v, i) => i * 10 + 'K',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        display: false
      }
    }
  }
}

</script>
<template>
  <PageTitle :icon="require('../../assets/images/HouseholdIcon.png')" title="Household" subtitle="Understanding household make-up in the area can improve the understanding of the customer base. Homeownership, family size, presence of children, and number of owned vehicles all provide insight into how to fit your car wash into their lives." :pdfLayout=pdfLayout />
  <section class="flex text-xl justify-content-between component-margin-y">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-6" :data="householdOverviewData.medianIncome" description="Household Median Income" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-6" :data="householdOverviewData.medianPropertyValue" description="Median Property Value" />
  </section>
  <section class="flex text-xl justify-content-between component-margin-y">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-6" :data="householdOverviewData.households" description="Households" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-6" :data="householdOverviewData.disposableIncome" description="Median Disposable Income" />
  </section>
  <GrayBarData class="component-margin-y" :data="householdOverviewData">
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-2" :data="householdOverviewData.personsPerHouse" description="Persons Per Household" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-2" :data="householdOverviewData.householdsWithChildren" description="Households with Children" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-2" :data="householdOverviewData.area" description="Area in Square Miles" />
  </GrayBarData>
  <section class="flex justify-content-between">
    <div class="col-5">
      <h2 class="chart-title">Household Status</h2>
      <PieChartComponent v-bind="{...householdStatusPie}" />
    </div>
    <div class="col-6">
      <h2 class="chart-title">Vehicles Per Household</h2>
      <BarChartComponent v-bind="{...vehiclesPerHouseChart}" :height="200" />
    </div>
  </section>
</template>
