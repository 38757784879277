import * as API from '@/services/API'

export default {
  fetchUser (userId) {
    return API.apiClient.get(`/users/${userId}`)
  },
  fetchUsers () {
    return API.apiClient.get('/users')
  }
}
