/*
 * This is the initial API interface
 * we set the base URL for the API
 ? Both request & response are logged to the console.
 ! Remove the console logs for production.
*/

import axios from 'axios'
import { store } from '../store/store'

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
  withCredentials: true, // required to handle the CSRF token
  headers: {
    Accept: 'application/json'
  }
})

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 419)
    ) {
      store.auth.logout()
    }
    return Promise.reject(error)
  }
)
