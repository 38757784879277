<script setup>
import DashboardMenu from '@/components/Dashboard/DashboardMenu.vue'
import DashboardContent from '@/components/Dashboard/DashboardContent.vue'
import { computed, ref, watch, defineProps } from 'vue'

const props = defineProps({
  external: {
    type: Boolean,
    default: false
  }
})

const reports = ref(null)
const getReports = async () => {
  // TODO GET REPORTS FROM API
  await new Promise((resolve) => setTimeout(resolve, 5000))

  reports.value = [
    {
      id: 1,
      name: 'Hype Car Wash',
      address: '1234 S West Drive 83700, Boise, ID',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 2,
      name: 'North Coast Car Wash',
      address: '5694 S East Ave 83730, Meridian, ID',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 3,
      address: 'Address 3',
      status: 'Needs Reviewed',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 4,
      address: 'Address 4',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 5,
      address: 'Address 5',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 6,
      address: 'Address 6',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 7,
      address: 'Address 7',
      status: 'Generating',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 8,
      address: 'Address 8',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 9,
      address: 'Address 9',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 10,
      address: 'Address 10',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 11,
      address: 'Address 11',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 12,
      address: 'Address 12',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 13,
      address: 'Address 13',
      status: 'Needs Reviewed',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 14,
      address: 'Address 14',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 15,
      address: 'Address 15',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 16,
      address: 'Address 16',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 17,
      address: 'Address 17',
      status: 'Generating',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 18,
      address: 'Address 18',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 19,
      address: 'Address 19',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 20,
      address: 'Address 20',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 21,
      address: 'Address 21',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 22,
      address: 'Address 22',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 23,
      address: 'Address 23',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 24,
      address: '1234 S West Drive 83700, Boise, ID',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 25,
      address: '5694 S East Ave 83730, Meridian, ID',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '01/07/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 26,
      address: 'Address 24',
      status: 'Needs Reviewed',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 27,
      address: 'Address 25',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 28,
      address: 'Address 26',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 29,
      address: 'Address 27',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 30,
      address: 'Address 28',
      status: 'Generating',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 31,
      address: 'Address 29',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 32,
      address: 'Address 30',
      status: 'Draft',
      lastEdited: '01/08/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 33,
      address: 'Address 31',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 34,
      address: 'Address 32',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 35,
      address: 'Address 33',
      status: 'Published',
      lastEdited: '02/09/2024',
      viewedOn: '02/09/2024',
      latestVersion: '02/09/2024',
      disabled: true
    },
    {
      id: 36,
      address: 'Address 34',
      status: 'Needs Reviewed',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 37,
      address: 'Address 35',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 38,
      address: 'Address 36',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 39,
      address: 'Address 37',
      status: 'Generated',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 40,
      address: 'Address 38',
      status: 'Generating',
      lastEdited: null,
      viewedOn: null,
      latestVersion: '02/09/2024',
      disabled: false
    },
    {
      id: 41,
      address: 'Address 39',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 42,
      address: 'Address 40',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: true
    },
    {
      id: 43,
      address: 'Address 41',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 44,
      address: 'Address 42',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 45,
      address: 'Address 43',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    },
    {
      id: 46,
      address: 'Address 44',
      status: 'Draft',
      lastEdited: '02/09/2024',
      viewedOn: null,
      latestVersion: null,
      disabled: false
    }
  ]
}
await getReports()

const rows = 10
const currentPage = ref(0)
const dashboardSearch = ref('')
const dashboardFilter = ref('')
const showDisabledReports = ref()

const reportsPerPage = computed(() => {
  const startIndex = (currentPage.value) * rows
  const endIndex = startIndex + rows
  return filteredReports.value.slice(startIndex, endIndex)
})

const handlePageChange = (event) => {
  const previousPage = currentPage.value
  currentPage.value = event.page
  window.scrollTo({ top: 0, behavior: 'smooth' })
  if (previousPage > currentPage.value) {
    window.scrollTo(0, document.body.scrollHeight)
  }
}

function getDashboardSearchResults (currentReport) {
  return currentReport.address.toLowerCase().includes(dashboardSearch.value.toLowerCase())
}

function getDashboardFilterResults (currentReport) {
  if (dashboardFilter.value === 'client view date') {
    return currentReport.viewedOn !== null
  } else {
    return currentReport.status.toLowerCase().includes(dashboardFilter.value)
  }
}

function getDashboardDisabledResults (currentReport) {
  return currentReport.disabled === true
}

const filteredReports = computed(() => {
  return reports.value.filter((report) => {
    if (props.external && !report.disabled) {
      return report.status === 'Published'
    } else {
      if (dashboardSearch.value && !report.disabled) {
        return getDashboardSearchResults(report)
      } else if (dashboardFilter.value && !report.disabled) {
        return getDashboardFilterResults(report)
      } else if (showDisabledReports.value) {
        return getDashboardDisabledResults(report)
      } else {
        return report.disabled === false
      }
    }
  })
})

const handleSearchChange = (event) => {
  dashboardSearch.value = event.toLowerCase()
}

const handleFilterChange = (value) => {
  dashboardFilter.value = value.toLowerCase()
}

const toggleDisabledVisibility = (value) => {
  showDisabledReports.value = value
}

watch(filteredReports, () => {
  currentPage.value = 0
})
</script>

<template>
  <DashboardMenu :external="external" @search-change="handleSearchChange" @filter-change="handleFilterChange" @disabled-reports-visibility="toggleDisabledVisibility"/>
  <DashboardContent :external="external" :reports="reportsPerPage" />
  <section class="flex justify-content-end suds-dk-blue align-items-center">
    <PaginatorComponent
        @page="handlePageChange($event)"
        :rows="rows"
        :totalRecords="filteredReports.length">
      <template #start="slotProps">
        <span>{{ slotProps.state.first }}</span>-<span>{{ (slotProps.state.first + rows) > filteredReports.length ? filteredReports.length : (slotProps.state.first + rows) }}</span>/<span>{{filteredReports.length}}</span>
      </template>
    </PaginatorComponent>
  </section>
</template>

<style scoped>

</style>
