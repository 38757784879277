import { store } from '@/store/store'

export default function auth ({ to, next }) {
  const authUser = store.auth.getters.authUser()
  const loginQuery = { path: '/login', query: { redirect: to.fullPath } }

  if (!authUser) {
    store.auth.getAuthUser().then(() => {
      if (!store.auth.getters.authUser()) next(loginQuery)
      else next()
    })
  } else {
    next()
  }
}
