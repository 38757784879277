<script setup>
import AppDataTable from '@/components/AppDataTable'
import { defineProps } from 'vue'

defineProps({
  pdfLayout: Boolean
})

const naturalDisasterTable = {
  tableColumns: [
    {
      field: 'disaster',
      header: 'Disaster'
    },
    {
      field: 'risk',
      header: 'Risk'
    }
  ],
  tableData: [
    {
      disaster: 'Risk of Earthquake',
      risk: 'Moderate to Minimal'
    },
    {
      disaster: 'Risk of Flood',
      risk: 'Moderate to Minimal'
    },
    {
      disaster: 'Flood Hazard Distance',
      risk: '659 mi.'
    },
    {
      disaster: 'Risk of Smoke',
      risk: 'Minimal'
    },
    {
      disaster: 'Nearest Fire Department',
      risk: '3.15 mi.'
    }
  ]
}
const emergencyServicesTable = {
  tableColumns: [
    {
      field: 'service',
      headerStyle: {
        display: 'none'
      }
    },
    {
      field: 'distance',
      headerStyle: {
        display: 'none'
      }
    }
  ],
  tableData: [
    {
      service: 'Nearest Fire Department',
      distance: '3.15 mi.'
    },
    {
      service: 'Fire Department Travel Time',
      distance: '5.48 mi.'
    }
  ]
}
</script>

<template>
  <div>
    <h2 class="chart-title">Natural Disasters</h2>
    <app-data-table
      class="component-margin-y"
      :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
      :columns="naturalDisasterTable.tableColumns"
      :title="naturalDisasterTable.title"
      :value="naturalDisasterTable.tableData"
    />
  </div>
  <div>
    <h2 class="chart-title">Emergency Services</h2>
    <div class="emergencyServicesTable">
      <app-data-table
        :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
        tableClass="emergencyServices"
        :columns="emergencyServicesTable.tableColumns"
        :value="emergencyServicesTable.tableData"
      />
    </div>
  </div>
</template>

<style scoped>
.emergencyServicesTable {
  border: solid white 2px;
  border-radius: 5px;
  overflow: hidden;
}
</style>
