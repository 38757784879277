<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import mapMarker from '../assets/images/MapMarker.png'
import poiMarker from '../assets/images/poi-marker.png'

const props = defineProps({
  centerAddress: String,
  zoom: Number,
  title: String,
  radius: Number,
  pointsOfInterest: Array,
  height: Number
})

const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
const mapElement = ref(null)
const map = ref(null)
const circle = ref(null)
const geocoder = ref(null)
const mainMarkers = ref([])
const poiMarkers = ref([])
const emit = defineEmits(['map-rendered'])

const loader = new Loader({
  apiKey: apiKey,
  version: 'weekly',
  libraries: ['places', 'geometry']
})

const mapOptions = {
  center: { lat: null, lng: null },
  poiAddresses: { lat: null, lng: null },
  zoom: props.zoom,
  title: props.title,
  radius: props.radius,
  mainMarkerIcon: mapMarker,
  poiMarkerIcon: poiMarker,
  styles: [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#c0c0c0' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text',
      stylers: [{ visibility: 'on' }]
    }
  ]
}

const initializeMap = async () => {
  try {
    const { Map } = await loader.importLibrary('maps')
    geocoder.value = new window.google.maps.Geocoder()
    const results = await geocodeAddress(props.centerAddress)
    setMapCenter(results[0].geometry.location, Map)
    createMainMarker()
    createCircle()
    await createPOIMarkers()
    // Wait for maps to fully load before emitting event
    map.value.addListener('tilesloaded', () => {
      emit('map-rendered')
    })
  } catch (error) {
    console.error('Error initializing the map:', error)
  }
}

const setMapCenter = (location, Map) => {
  mapOptions.center.lat = location.lat()
  mapOptions.center.lng = location.lng()
  map.value = new Map(mapElement.value, mapOptions)
}

const createMainMarker = () => {
  const mainMarker = new window.google.maps.Marker({
    position: mapOptions.center,
    map: map.value,
    icon: mapOptions.mainMarkerIcon,
    title: mapOptions.title
  })
  mainMarkers.value.push(mainMarker)
}

const createCircle = () => {
  if (!map.value || !mapOptions.center) {
    console.error('Map or map center is not defined')
    return
  }

  circle.value = new window.google.maps.Circle({
    map: map.value,
    center: mapOptions.center,
    fillColor: '#2FBCCB',
    fillOpacity: 0.35,
    strokeColor: '#2FBCCB',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    radius: props.radius
  })
}

const createPOIMarkers = async () => {
  for (const poi of props.pointsOfInterest) {
    const results = await geocodeAddress(poi.address)
    if (results) {
      const location = results[0].geometry.location
      if (isPointWithinCircle(location, circle)) {
        const poiMarker = new window.google.maps.Marker({
          position: location,
          map: map.value,
          icon: mapOptions.poiMarkerIcon,
          title: poi.title
        })
        poiMarkers.value.push(poiMarker)
      }
    }
  }
}

const isPointWithinCircle = (point, circle) => {
  if (!circle.value) {
    console.error('Circle is not defined')
    return false
  }

  const circleRadius = circle.value.getRadius()
  const circleCenter = circle.value.getCenter()
  const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
    new window.google.maps.LatLng(point),
    circleCenter
  )
  if (distance <= circleRadius) {
    return true
  }
}

const geocodeAddress = async (address) => {
  return new Promise((resolve, reject) => {
    geocoder.value = new window.google.maps.Geocoder()
    geocoder.value.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        resolve(results)
      } else {
        console.error('Geocode was not successful:', status)
        reject(status)
      }
    })
  })
}

onMounted(initializeMap)

watch(() => props.centerAddress, initializeMap)
</script>

<template>
  <div id="map" ref="mapElement" class="map" :style="{ height: height + 'px' }"></div>
</template>

<style scoped>
.map {
  width: 100%;
  border: 5px solid var(--suds-lt-blue);
}
</style>
