<script setup>
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { defineProps, onMounted, ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  data: {
    type: Object,
    required: true
  },
  pdfLayout: {
    type: Boolean,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const plugins = [ChartDataLabels]
const optionsData = ref(props.options)

onMounted(() => {
  if (props.pdfLayout) {
    optionsData.value.borderWidth = 1
  }
})
</script>

<template>
  <h5 v-if="title" class="suds-dk-blue font-suds-bold text-left" :class="{ 'mb-0 text-xl' : !pdfLayout, 'mt-3 mb-2 text-xs' : pdfLayout }">{{ title }}</h5>
  <ChartComponent type="bar"
                  :title="title"
                  :height="height"
                  :data="data"
                  :options="options"
                  :plugins="plugins"
  />
</template>
