<script setup>
import BarChartComponent from '@/components/BarChartComponent.vue'
import { defineProps } from 'vue'

defineProps({
  pdfLayout: Boolean
})

const housingStructureChart = {
  title: '',
  data: {
    labels: ['Single Unit', 'Multi-Unit', 'Mobile Home', 'Boat, RV, van, etc.'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [7813, 1169, 292, 18]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 200,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -200,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Structures',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false
        },
        beginAtZero: true,
        afterBuildTicks: axis => {
          axis.ticks = [
            { value: 0 },
            { value: 3000 },
            { value: 6000 },
            { value: 9000 }
          ]
        },
        ticks: {
          stepSize: 10000,
          callback: (v, i) => i * 3 + 'K',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        display: false
      }
    }
  }
}

const ownerOccupiedHousingUnitChart = {
  title: '',
  data: {
    labels: ['Less Than $100K', '$100K - 200K', '$200K - 300K', '$300K - 400K', '$400K - 500K', '$500K - 1M', 'Over $1M'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [443, 1974, 3400, 1270, 231, 88, 21]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 200,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -200,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Housing Units',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          stepSize: 500,
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        display: false
      }
    }
  }
}
</script>

<template>
  <div>
    <h2 class="chart-title">Housing Structure Types</h2>
    <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...housingStructureChart}" :height="100" />
  </div>
  <div class="component-margin-y">
    <h2 class="chart-title">Value of Owner-Occupied Housing Units</h2>
    <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...ownerOccupiedHousingUnitChart}" :height="100" />
  </div>
</template>

<style scoped>
</style>
