<script setup>
</script>

<template>
  <section class="full-w-component suds-ltst-gray-bg text-3xl">
    <div class="gray-bar-padding flex justify-content-between">
      <slot></slot>
    </div>
  </section>
</template>

<style>
.gray-bar-padding {
  padding: 16px 5rem;
}
</style>
