<script setup>
import BaseButton from '@/components/FormComponents/BaseButton'
import { computed, defineProps, ref } from 'vue'

const props = defineProps({
  report: {
    type: Object,
    required: true
  },
  external: {
    type: Boolean,
    default: false
  }
})

const hover = ref(false)

const statusImage = computed(() => {
  if (props.report.disabled) {
    return require('../../assets/images/icons/disabled-icon.png')
  } else {
    switch (props.report.status) {
      case 'Published':
        return require('../../assets/images/icons/published-icon.png')
      case 'Needs Reviewed':
        return require('../../assets/images/icons/needs-reviewed-icon.png')
      case 'Generated':
        return require('../../assets/images/icons/generated-icon.png')
      case 'Generating':
        return require('../../assets/images/icons/generating-icon.png')
      case 'Draft':
        return require('../../assets/images/icons/draft-icon.png')
      default:
        return null
    }
  }
})

const statusBorderClass = computed(() => {
  if (props.external) {
    return 'transparent'
  } else {
    if (props.report.disabled) {
      return 'status-disabled'
    } else {
      switch (props.report.status) {
        case 'Published':
          return 'status-published'
        case 'Needs Reviewed':
          return 'status-needs-reviewed'
        case 'Generated':
          return 'status-generated'
        case 'Generating':
          return 'status-generating'
        case 'Draft':
          return 'status-draft'
        default:
          return null
      }
    }
  }
})
</script>

<template>
  <div class="flex justify-content-between h-7rem border-left-3 suds-off-white-bg hover:bg-white row-padding" @mouseover="hover = true" @mouseleave="hover = false" :class="statusBorderClass">
    <div class="w-6 flex align-items-center justify-content-between">
      <div class="col-1">
          <img v-if="!external" :src="statusImage" alt="Status Icon" width="34">
          <img v-else src="../../assets/images/icons/external-icon.png" alt="Status Icon" width="16">
      </div>
      <div class="col-5 text-left">
        <p v-if="!report.disabled" class="suds-dk-blue text-xl proxima bold">{{report.address}}</p>
        <p v-else class="suds-lt-gray text-xl proxima bold">{{report.address}}</p>
      </div>
      <div v-if="!external" class="col-6">
        <div v-if="!report.disabled" class="suds-dk-blue text-left proxima">
          <p v-if="report.status">Status: <span>{{report.status}}</span></p>
          <p v-if="report.lastEdited">Last Edited: <span>{{report.lastEdited}}</span></p>
          <p v-if="report.viewedOn">Client Viewed On: <span>{{report.viewedOn}}</span></p>
          <p v-if="report.latestVersion">Latest Version: <span>{{report.latestVersion}}</span></p>
        </div>
        <div v-else class="suds-lt-gray text-left proxima">
          <p>Status: <span>Disabled</span></p>
          <p v-if="report.status">Report Status: <span>{{report.status}}</span></p>
        </div>
      </div>
      <div v-else class="col-6 suds-dk-blue text-left proxima">
        <p v-if="report.latestVersion">Published On: <span>{{report.latestVersion}}</span></p>
      </div>
    </div>
    <div v-if="hover && external" class="flex justify-content-end align-items-center gap-6 w-6">
      <a href="#" class="mx-4">
        <img src="../../assets/images/share-icon.png" alt="Share" width="34" height="34">
      </a>
      <router-link class="no-underline" :to="{ name: 'ReportView', params:{ reportName: report.name }}">
        <BaseButton text="View Report" class="btn btn-secondary no-margin" />
      </router-link>
      <BaseButton text="Export" class="btn btn-secondary no-margin" />
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 36px;
}
p {
  margin: .2em 0;
}
.no-margin {
  margin: 0;
}
.row-padding {
  padding: 36px 30px;
}
</style>
