<script setup>
</script>

<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://use.typekit.net/ycj6slm.css');
@import 'primevue/resources/themes/saga-blue/theme.css';     //theme
@import 'primevue/resources/primevue.min.css';      //core css
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.min.css';
@import './css/app.css';
@import './css/_colors.scss';
@import './css/_report.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
