<script setup>
import BatteryChartComponent from '@/components/BatteryChartComponent.vue'
import * as pattern from 'patternomaly'
import AppDataTable from '@/components/AppDataTable.vue'
import PageTitle from '@/components/PageTitle.vue'
import RadiusMapComponent from '@/components/RadiusMapComponent.vue'
import { defineEmits, defineProps } from 'vue'

defineProps({
  pdfLayout: Boolean
})

const positionPDF = [
  { left: 70, bottom: 230 },
  { left: 120, bottom: 215 },
  { left: 185, bottom: 213 },
  { left: 260, bottom: 215 },
  { left: 405, bottom: 235 },
  { left: 390, bottom: 213 },
  { left: 420, bottom: 210 }
]

const positionDesktop = [
  { left: 135, bottom: 180 },
  { left: 300, bottom: 170 },
  { left: 435, bottom: 165 },
  { left: 555, bottom: 170 },
  { left: 755, bottom: 185 },
  { left: 775, bottom: 170 },
  { left: 910, bottom: 155 }
]

const pointsOfInterest = [
  { address: '3499 E Fairview Ave, Meridian, ID 83642', title: 'Albertsons Market' },
  { address: '4051 E Fairview Ave, Meridian, ID 83642', title: 'Walmart Super Center' },
  { address: '2350 N Eagle Rd, Meridian, ID 83646', title: 'Boise Co-Op' },
  { address: '3520 E Fairview Ave, Meridian, ID 83642', title: 'In-N-Out' },
  { address: '2150 N Eagle Rd, Meridian, ID 83646', title: 'Nike Factory' },
  { address: '2012 N Eagle Rd, Meridian, ID 83646', title: 'Chick-fil-A' }
]

const mainPointOfInterest = { address: '3540 E Longwing Ln Suite 220 Meridian, ID 83642', title: 'Suds Creative' }

const locationTable = {
  tableColumns: [
    {
      field: 'name',
      header: 'Name'
    },
    {
      field: 'category',
      header: 'Category'
    },
    {
      field: 'distance',
      header: 'Distance (mi)',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      name: 'Acadia Crossing',
      category: 'Shopping Center',
      distance: '.06 mi'
    },
    {
      name: 'Walmart',
      category: 'Superstore',
      distance: '.24 mi'
    },
    {
      name: 'Home Depot',
      category: 'Home Improvement',
      distance: '.83 mi'
    },
    {
      name: 'T.J. Maxxx',
      category: 'Neighborhood Center',
      distance: '.74 mi'
    },
    {
      name: 'Ellsworth Shopping Center',
      category: 'Grocery',
      distance: '.34 mi'
    },
    {
      name: "Wendy's",
      category: 'Fast Food',
      distance: '.44 mi'
    }
  ]
}

const batteryChart = {
  title: '',
  height: 60,
  data: {
    labels: [''],
    datasets: [
      {
        type: 'bar',
        label: 'Grocery Stores',
        backgroundColor: pattern.draw('diagonal', '#1be1f2'),
        borderSkipped: false,
        datalabels: {
          color: '#00263A'
        },
        data: [23.8]
      },
      {
        type: 'bar',
        label: 'Big Box Department stores',
        backgroundColor: '#00263A',
        datalabels: {
          color: '#FFF'
        },
        data: [14]
      },
      {
        type: 'bar',
        label: 'Specialty Department Stores',
        backgroundColor: '#616161',
        datalabels: {
          color: '#FFF'
        },
        data: [9.5]
      },
      {
        type: 'bar',
        label: 'Home Improvement Stores',
        backgroundColor: '#8f8f8f',
        datalabels: {
          color: '#FFF'
        },
        data: [9.5]
      },
      {
        type: 'bar',
        label: 'Pharmacies',
        backgroundColor: '#C1C1C1',
        datalabels: {
          color: '#000'
        },
        data: [9.5]
      },
      {
        type: 'bar',
        label: 'Fast Food Restaurants',
        backgroundColor: '#d2d0d0',
        datalabels: {
          color: '#000'
        },
        data: [4]
      },
      {
        type: 'bar',
        label: 'Chain Restaurants (Non-Fast Food)',
        backgroundColor: '#FFFFFF',
        datalabels: {
          color: '#000'
        },
        data: [29.7]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    borderColor: '#000',
    borderWidth: 2,
    layout: {
      padding: {
        bottom: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          value: {
            anchor: 'center',
            align: 'bottom',
            offset: 15,
            font: {
              weight: 'bold',
              size: 20
            },
            formatter: function (value) {
              return `${value}%`
            }
          }
        }
      }
    },
    style: {
      height: 100,
      width: 100
    },
    scales: {
      x: {
        display: false,
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  }
}

const batteryChartPDF = {
  title: '',
  height: 60,
  data: {
    labels: [''],
    datasets: [
      {
        type: 'bar',
        label: 'Grocery Stores',
        backgroundColor: pattern.draw('diagonal', '#1be1f2'),
        borderRadius: 10,
        borderSkipped: false,
        data: [23.8]
      },
      {
        type: 'bar',
        label: 'Big Box Department stores',
        backgroundColor: '#00263A',
        data: [14]
      },
      {
        type: 'bar',
        label: 'Specialty Department Stores',
        backgroundColor: '#616161',
        data: [9.5]
      },
      {
        type: 'bar',
        label: 'Home Improvement Stores',
        backgroundColor: '#8f8f8f',
        data: [9.5]
      },
      {
        type: 'bar',
        label: 'Pharmacies',
        backgroundColor: '#C1C1C1',
        data: [9.5]
      },
      {
        type: 'bar',
        label: 'Fast Food Restaurants',
        backgroundColor: '#d2d0d0',
        data: [4]
      },
      {
        type: 'bar',
        label: 'Chain Restaurants (Non-Fast Food)',
        backgroundColor: '#FFFFFF',
        data: [29.7]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    borderColor: '#000',
    borderWidth: 1,
    layout: {
      padding: {
        bottom: 60
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            display: false
          },
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        display: false,
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  }
}

const batteryChartLabelColors = ['#00263A', '#FFF', '#FFF', '#FFF', '#000', '#000', '#000']

const style = (i) => {
  return { width: `${batteryChartPDF.data.datasets[i].data}vw`, color: `${batteryChartLabelColors[i]}` }
}

const labelStyle = (i, position) => {
  return {
    position: 'absolute',
    left: `${position[i].left}px`,
    bottom: `${position[i].bottom}px`
  }
}

const emit = defineEmits(['high-traffic-map-rendered'])
function highTrafficMapRendered () {
  console.log('high-traffic-map-rendered')
  emit('high-traffic-map-rendered')
}
</script>

<template>
    <PageTitle :subPage="true" title="Frequently Visited Retail (Within 1 mile)" subtitle="High frequency retail refers to placements of retailers that affect traffic patterns and trade area density. Car washing is considered a convenience errand, so targetting people who are traveling to or from these high frequency retailers has been proven effective. Categories we look at are grocery stores, big box department stores (Walmart, Target), specialty department (Hobby Lobby, Ulta), home improvement, pharmacy, fast food restaurants, chain restaurants (non-fast food)." :pdf-layout=pdfLayout  />
    <div v-if="pdfLayout" class="component-margin-y">
      <RadiusMapComponent
        @map-rendered="highTrafficMapRendered"
        class="w-full mb-5"
        :height="300"
        :centerAddress="mainPointOfInterest.address"
        :zoom="13.25"
        :title="mainPointOfInterest.title"
        :radius="1609.34"
        :pointsOfInterest="pointsOfInterest"
      />
    </div>
  <div v-else class="component-margin-y">
    <RadiusMapComponent
      class="w-full mb-5"
      :height="500"
      :centerAddress="mainPointOfInterest.address"
      :zoom="14"
      title="Suds Creative"
      :radius="1609.34"
      :pointsOfInterest="pointsOfInterest"
    />
  </div>
    <app-data-table
      class="component-margin-y"
      :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
      :columns="locationTable.tableColumns"
      :title="locationTable.title"
      :value="locationTable.tableData"
    />
    <div v-if="pdfLayout">
      <BatteryChartComponent :pdfLayout="pdfLayout" v-bind="{...batteryChartPDF}" :height="70" />
      <div class="flex">
        <span
          v-for="(value, i) in batteryChartPDF.data.datasets"
          class="battery-values-pdf"
          :key="i"
          :style="style(i)"
          >
          {{ value.data[0] }}%
        </span>
      </div>
      <div class="flex">
        <span
          v-for="(value, i) in batteryChartPDF.data.datasets"
          class="battery-label-pdf font-suds-medium"
          :key="i"
          :style="labelStyle(i, positionPDF)"
        >
          {{ value.label }}
        </span>
      </div>
    </div>
    <div v-else>
      <BatteryChartComponent v-bind="{...batteryChart}" :height="60" :pdfLayout="pdfLayout" />
      <div class="flex">
        <span
          v-for="(value, i) in batteryChart.data.datasets"
          class="battery-label-pdf font-suds-medium"
          :key="i"
          :style="labelStyle(i, positionDesktop)"
        >
          {{ value.label }}
        </span>
      </div>
    </div>
</template>

<style scoped>
.battery-values-pdf{
  text-align: center;
  margin-top: -110px;
  position: relative;
  z-index: 9999;
}
.battery-label-pdf{
  text-align: center;
  transform: rotate(-25deg); /* Forced to do transform here with html2pdf constraints */
  margin-top: -45px;
  font-size: small;
}

</style>
