<script setup>
import { defineProps } from 'vue'
import AppDataTable from '@/components/AppDataTable.vue'
import ColumnData from '@/components/ColumnData.vue'
import GrayBarData from '@/components/GrayBarData.vue'

defineProps({
  pdfLayout: Boolean
})

const laborForceTable = {
  tableColumns: [
    {
      field: 'status',
      header: 'Employment Status'
    },
    {
      field: 'population',
      header: 'Population',
      style: 'column-right-align'
    },
    {
      field: 'percentage',
      header: 'Percentage',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      status: 'English',
      population: '244,161',
      percentage: '82%'
    },
    {
      status: 'Spanish',
      population: '65,643',
      percentage: '18%'
    }
  ]
}

const employmentByOccupationTable = {
  tableColumns: [
    {
      field: 'occupation',
      header: 'Occupation'
    },
    {
      field: 'population',
      header: 'Population',
      style: 'column-right-align'
    },
    {
      field: 'percentage',
      header: 'Percentage',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      occupation: 'Building Maintenance & Cleaning',
      population: '1,976',
      percentage: '2.181%'
    },
    {
      occupation: 'Construction',
      population: '4,918',
      percentage: '5.430%'
    },
    {
      occupation: 'Farming, Fishing, & Forestry',
      population: '430',
      percentage: '0.474%'
    },
    {
      occupation: 'Food Preparation & Serving',
      population: '4,895',
      percentage: '5.405%'
    },
    {
      occupation: 'Healthcare Support',
      population: '2,417',
      percentage: '2.668%'
    },
    {
      occupation: 'Managerial/Executive',
      population: '18,260',
      percentage: '20.16%'
    },
    {
      occupation: 'Office Admin',
      population: '9,895',
      percentage: '10.92%'
    },
    {
      occupation: 'Personal Care',
      population: '3,398',
      percentage: '3.752%'
    },
    {
      occupation: 'Production & Transportation',
      population: '8,526',
      percentage: '9.414%'
    },
    {
      occupation: 'Professional Specialty',
      population: '22,597',
      percentage: '24.95%'
    },
    {
      occupation: 'Protective',
      population: '1,956',
      percentage: '2.159%'
    },
    {
      occupation: 'Sales',
      population: '11,296',
      percentage: '12.47%'
    }
  ]
}

const laborForceData = {
  employeeAgeSixteen: '90,564',
  transientPopulation: '0.34%'
}
</script>

<template>
  <div>
    <h2 class="chart-title">Labor Force</h2>
    <app-data-table
        class="component-margin-y"
        :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
        :columns="laborForceTable.tableColumns"
        :value="laborForceTable.tableData"
    />
  </div>
  <div>
    <h2 class="chart-title">Employment By Occupation</h2>
    <app-data-table
        class="component-margin-y"
        :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
        :columns="employmentByOccupationTable.tableColumns"
        :value="employmentByOccupationTable.tableData"
    />
  </div>
  <GrayBarData :data="laborForceData" class="component-margin-y">
    <ColumnData fontData="data-lg" fontDescription="data-title-sm" col="col-3" :data="laborForceData.employeeAgeSixteen" description="Employees Age 16+" />
    <ColumnData fontData="data-lg" fontDescription="data-title-sm" col="col-7" :data="laborForceData.transientPopulation" description="Transient Population*" />
  </GrayBarData>
</template>

<style scoped>
</style>
