<script setup>
import { defineEmits, ref } from 'vue'

const checked = ref(false)

const emit = defineEmits(['disabled-reports-visibility'])

const toggleDisabledVisibility = () => {
  emit('disabled-reports-visibility', checked.value)
}

</script>

<template>
  <Check-box v-model="checked" name="disabled_reports" :binary="true" @change="toggleDisabledVisibility"/>
  <label class="pl-2" for="disabled_reports">Show Disabled Reports</label>
</template>

<style scoped>
  label {
    color: #838383;
  }
</style>
