<script setup>
import PageTitle from '@/components/PageTitle.vue'
import LineChartComponent from '@/components/LineChartComponent.vue'
import { defineProps } from 'vue'
import BarChartComponent from '@/components/BarChartComponent.vue'
import * as pattern from 'patternomaly'

defineProps({
  pdfLayout: Boolean
})

const ageBreakdownChart = {
  data: {
    type: 'line',
    labels: ['0-5', '5-15', '15-18', '18-22', '22-25', '25-30', '30-35', '35-40', '40-45', '45-50', '50-55', '55-60', '60-65', '65-70', '70-75', '75-80', '80-85', '85+'],
    datasets: [{
      label: 'Age  Breakdown',
      data: [1500, 4000, 1800, 2000, 1200, 1200, 2100, 2300, 1700, 1750, 2050, 1700, 1200, 1200, 1100, 700, 700, 0],
      fill: false,
      borderColor: '#45C3D2',
      pointBackgroundColor: '#45C3D2',
      pointRadius: 5
    }],
    yAxisID: 'Age'
  },
  options: {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'start',
          text: 'Age',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxRotation: 35,
          minRotation: 35,
          padding: 0,
          margin: 0,
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        beginAtZero: false,
        ticks: {
          stepSize: 500,
          font: {
            weight: 'bold',
            size: 14
          }
        }
      }
    }
  }
}

const genderByAgeChart = {
  type: 'bar',
  title: '',
  data: {
    labels: ['0-5', '5-15', '15-18', '18-22', '22-25', '25-30', '30-35', '35-40', '40-45', '45-50', '50-55'],
    datasets: [
      {
        type: 'bar',
        label: 'Female',
        backgroundColor: '#00263A',
        data: [1000, 2200, 850, 1100, 950, 1600, 1500, 1550, 1500, 1500, 1600],
        datalabels: {
          labels: {
            value: {
              display: false
            }
          }
        }
      },
      {
        type: 'bar',
        label: 'Male',
        backgroundColor: pattern.draw('diagonal', '#1be1f2'),
        data: [1000, 2000, 750, 850, 750, 1200, 1200, 1200, 1100, 900, 900],
        datalabels: {
          labels: {
            value: {
              display: false
            }
          }
        }
      }
    ]
  },
  options: {
    indexAxis: 'x',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 1,
    categoryPercentage: 0.8,
    layout: {
      padding: {
        left: 30,
        right: 50
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        reverse: false,
        labels: {
          boxHeight: 20,
          boxWidth: 37,
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      }
    },
    scales: {
      x: {
        stacked: false,
        display: true,
        grid: {
          drawBorder: false,
          display: false
        },
        ticks: {
          maxRotation: 35,
          minRotation: 35,
          font: {
            size: 18,
            weight: 'bold'
          },
          color: '#00263A'
        }
      },
      y: {
        stacked: false,
        display: true,
        beginAtZero: true,
        title: {
          display: false
        },
        grid: {
          drawBorder: false,
          display: false
        },
        ticks: {
          stepSize: 500,
          font: {
            size: 18,
            weight: 'bold'
          },
          color: '#616161'
        }
      }
    }
  }
}
</script>

<template>
  <PageTitle :icon="require('../../assets/images/demographics-icon.png')" title="Demographics" subtitle="Demographics of the people within the trade area of the site give you insight into who you are trying to attract to your business. Measures such as age, gender, ethnicity, employment status, and area of employment paint a picture of your audience." :pdf-layout=pdfLayout />
  <section class="h-full full-w-component suds-ltst-gray-bg component-margin-y">
    <div class="gray-bar-padding">
      <h2 class="chart-title">
        Age Breakdown
      </h2>
      <LineChartComponent :data="ageBreakdownChart.data" :options="ageBreakdownChart.options" :height="100"/>
    </div>
  </section>
  <section class="component-margin-y">
    <h2>
      Gender by Age
    </h2>
    <div v-if="pdfLayout">
      <BarChartComponent v-bind="{...genderByAgeChart}" :height="150"/>
    </div>
    <div v-else>
      <BarChartComponent v-bind="{...genderByAgeChart}" :height="75"/>
    </div>
  </section>
</template>

<style scoped>
</style>
