<script setup>
import Vue3Html2pdf from 'vue3-html2pdf'
import ReportLayout from '@/layouts/ReportLayout.vue'
import CoverPage from '@/views/CoverPage.vue'
import LocationSummary from '@/views/LocationSummaryPages/LocationSummary.vue'
import HighTrafficRetailPage from '@/views/LocationSummaryPages/HighTrafficRetailView.vue'
import TradeAreaOverview from '@/views/LocationSummaryPages/TradeAreaOverview.vue'
import NearbyRetailPage from '@/views/LocationSummaryPages/NearbyRetailView.vue'
import HouseholdOverview from '@/views/HouseholdPages/HouseholdOverview.vue'
import IncomeView from '@/views/HouseholdPages/IncomeView.vue'
import GrowthOverTime from '@/views/HouseholdPages/GrowthOverTime.vue'
import PovertyAndOccupancy from '@/views/HouseholdPages/PovertyAndOccupancy.vue'
import StructureView from '@/views/HouseholdPages/StructureView.vue'
import CrimePage from '@/views/RiskAssessmentPages/CrimeView.vue'
import NaturalDisastersPage from '@/views/RiskAssessmentPages/NaturalDisasters.vue'
import VehicleTrafficDataPageOne from '@/views/VehicleTrafficPages/VehicleTrafficDataPageOne.vue'
import VehicleTrafficDataPageTwo from '@/views/VehicleTrafficPages/VehicleTrafficDataPageTwo.vue'
import DemographicsPageOne from '@/views/DemographicsPages/DemographicsPageOne.vue'
import DemographicsPageTwo from '@/views/DemographicsPages/DemographicsPageTwo.vue'
import DemographicsPageThree from '@/views/DemographicsPages/DemographicsPageThree.vue'
import DemographicsPageFour from '@/views/DemographicsPages/DemographicsPageFour.vue'
import DefinitionsPageOne from '@/views/DefinitionsPages/DefinitionsPageOne.vue'
import DefinitionsPageTwo from '@/views/DefinitionsPages/DefinitionsPageTwo.vue'
import DefinitionsPageThree from '@/views/DefinitionsPages/DefinitionsPageThree.vue'
import DefinitionsPageFour from '@/views/DefinitionsPages/DefinitionsPageFour.vue'
import DefinitionsPageFive from '@/views/DefinitionsPages/DefinitionsPageFive.vue'
import { ref, onMounted, defineProps, computed, watch } from 'vue'

const pdfLayout = ref(false)
const html2Pdf = ref()
const nearbyRetailMapRendered = ref(false)
const highTrafficMapRendered = ref(false)
const props = defineProps({
  reportView: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  if (props.reportView) {
    pdfLayout.value = true
  }
})

async function generateReport () {
  html2Pdf.value.generatePdf()
}

function testpdfLayout () {
  pdfLayout.value = !pdfLayout.value
  const map = document.getElementById('map')
  if (pdfLayout.value) {
    map.scrollIntoView()
  }
}

const allMapsRendered = computed(() => {
  return nearbyRetailMapRendered.value && highTrafficMapRendered.value
})

watch(allMapsRendered, () => {
  if (allMapsRendered.value === true) {
    window.scrollTo(0, 0)
  }
})
</script>

<template>
  <div class="flex flex-column justify-content-center w-full report-bg">
    <div v-if="pdfLayout">
      <button @click="generateReport" :disabled="!allMapsRendered">Export to PDF</button>
    </div>
    <div>
      <button @click="testpdfLayout">Swap pdfLayout</button>
    </div>
    <vue3-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        filename="Site Select Report"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        :pdf-content-width="pdfLayout ? '800px' : '1365px'"
        ref="html2Pdf"
        class="m-auto"
    >
      <template v-slot:pdf-content>
        <div>
          <CoverPage
            streetAddress="123 Address Way"
            cityStateZip="City Name, ST 98760"
            name="Hype Car Wash"
            email="info@hypecarwash.com"
            date="06/10/2022"
            :pdfLayout='pdfLayout'
          />
          <ReportLayout :pageNumber=1 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <LocationSummary :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=2 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <TradeAreaOverview :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=3 :pdfLayout="pdfLayout">
            <NearbyRetailPage :pdfLayout="pdfLayout" @nearby-retail-map-rendered="nearbyRetailMapRendered = true"/>
          </ReportLayout>
          <ReportLayout :pageNumber=4 :pdfLayout="pdfLayout">
            <HighTrafficRetailPage id="map" :pdfLayout="pdfLayout" @high-traffic-map-rendered="highTrafficMapRendered = true"/>
          </ReportLayout>
          <ReportLayout :pageNumber=5 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <HouseholdOverview :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=6 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <IncomeView :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=7 :pdfLayout="pdfLayout">
            <GrowthOverTime :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=8 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <PovertyAndOccupancy :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=9 :pdfLayout="pdfLayout">
            <StructureView :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=10 :pdfLayout="pdfLayout">
            <CrimePage :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=11 :pdfLayout="pdfLayout">
            <NaturalDisastersPage :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=12 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <VehicleTrafficDataPageOne :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=13 :pdfLayout="pdfLayout">
            <VehicleTrafficDataPageTwo :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=14 :pdfLayout="pdfLayout">
            <DemographicsPageOne :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=15 :pdfLayout="pdfLayout">
            <DemographicsPageTwo :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=16 definition="*See definitions for details" :pdfLayout="pdfLayout">
            <DemographicsPageThree :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=17 definition="Demographic data provided by Placer AI" :pdfLayout="pdfLayout">
            <DemographicsPageFour :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=18 :pdfLayout="pdfLayout">
            <DefinitionsPageOne :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=19 :pdfLayout="pdfLayout">
            <DefinitionsPageTwo :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=20 :pdfLayout="pdfLayout">
            <DefinitionsPageThree :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=21 :pdfLayout="pdfLayout">
            <DefinitionsPageFour :pdfLayout="pdfLayout" />
          </ReportLayout>
          <ReportLayout :pageNumber=22 :pdfLayout="pdfLayout">
            <DefinitionsPageFive :pdfLayout="pdfLayout" />
          </ReportLayout>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>

<style lang="scss" scoped>

</style>
