<script setup>
import ColumnData from '@/components/ColumnData.vue'
import GrayBarData from '@/components/GrayBarData.vue'
import BarChartComponent from '@/components/BarChartComponent.vue'
import { defineProps } from 'vue'
import AppDataTable from '@/components/AppDataTable.vue'

defineProps({
  pdfLayout: Boolean
})
const povertyOverviewData = {
  householdsInPoverty: 641,
  averageHomeValue: '$345,201.66',
  medianRent: '$1,816.31'
}
const housingOccupancyTable = {
  tableColumns: [
    {
      field: 'occupancy',
      header: 'Occupancy Status'
    },
    {
      field: 'households',
      header: 'Households',
      style: 'column-right-align'
    },
    {
      field: 'percent',
      header: 'Percent',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      occupancy: 'Owner Occupied',
      households: '35,809',
      percent: `${44.98}%`
    },
    {
      occupancy: 'Renter Occupied',
      households: '31,621',
      percent: `${39.18}%`
    },
    {
      occupancy: 'Vacant',
      households: '3,604',
      percent: `${9.98}%`
    }
  ]
}
const housingPovertyTable = {
  tableColumns: [
    {
      field: 'poverty',
      header: 'Poverty Status'
    },
    {
      field: 'households',
      header: 'Households',
      style: 'column-right-align'
    },
    {
      field: 'percent',
      header: 'Percent',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      poverty: 'Households Above Poverty',
      households: '64,132',
      percent: `${91.98}%`
    },
    {
      poverty: 'Households In Poverty',
      households: '5,591',
      percent: `${8.018}%`
    }
  ]
}
const vacantHousingChart = {
  title: '',
  data: {
    labels: ['For Rent', 'Rented, Not Occupied', 'For Sale Only', 'Sold, Not Occupied', 'Seasonal/Recreational*', 'Other'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [605, 271, 362, 129, 1672, 500]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 200,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -200,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Households',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          stepSize: 500,
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        display: false
      }
    }
  }
}
</script>

<template>
  <GrayBarData :data="povertyOverviewData">
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="povertyOverviewData.householdsInPoverty" description="Households in Poverty" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="povertyOverviewData.averageHomeValue" description="Avg Home Value" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="povertyOverviewData.medianRent" description="Median Rent" />
  </GrayBarData>
  <div class="component-margin-y">
    <h2 class="chart-title">Housing Occupancy</h2>
    <app-data-table
        :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
        :columns="housingOccupancyTable.tableColumns"
        :title="housingOccupancyTable.title"
        :value="housingOccupancyTable.tableData"
    />
  </div>
  <div class="component-margin-y">
    <h2 class="chart-title">Household in Poverty*</h2>
    <app-data-table
        :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
        :columns="housingPovertyTable.tableColumns"
        :title="housingPovertyTable.title"
        :value="housingPovertyTable.tableData"
    />
  </div>
  <div class="component-margin-y">
    <h2 class="chart-title">Vacant Housing Detail</h2>
    <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...vacantHousingChart}" :height="100" />
  </div>
</template>

<style scoped>
</style>
