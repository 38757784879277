<script setup>
import PageTitle from '@/components/PageTitle.vue'
import ColumnData from '@/components/ColumnData.vue'
import GrayBarData from '@/components/GrayBarData.vue'
import BarChartComponent from '@/components/BarChartComponent.vue'
import { defineProps } from 'vue'
import LineChartComponent from '@/components/LineChartComponent.vue'

defineProps({
  pdfLayout: Boolean
})
const growthOverTimeData = {
  households: '67,430',
  fiveYrProjectedNumOfHouseholds: '70,215',
  fiveYrProjectedIncrease: '0.12%',
  tenYrProjectedNumOfHouseholds: '70,873',
  tenYrProjectedIncrease: '2.71%'
}
const growthOverTimeChart = {
  data: {
    type: 'line',
    labels: ['2019 Q4', '2020 Q1', '2020 Q2', '2020 Q3', '2020 Q4', '2021 Q1', '2021 Q2', '2021 Q3'],
    datasets: [{
      label: 'My First Dataset',
      data: [69200, 69550, 69600, 69575, 69625, 69610, 69700, 69650],
      fill: false,
      borderColor: '#45C3D2',
      pointBackgroundColor: '#45C3D2',
      pointRadius: 5
    }]
  },
  options: {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        grid: { display: false },
        ticks: {
          font: {
            weight: 'bold',
            size: 14
          }
        }
      }
    }
  }
}
const familySizeChart = {
  data: {
    labels: ['2 Persons', '3 Persons', '4 Persons', '5 Persons', '6 Persons', '7+ Persons'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [2893, 1284, 1660, 838, 176, 81]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 30,
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 150,
        right: 200
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -185,
            font: { size: 14, weight: 'bold' },
            padding: {
              right: 80
            },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: '# Of Families',
          color: '#00263A',
          font: {
            weight: 'bold',
            size: 14
          }
        },
        grid: {
          display: false
        },
        ticks: {
          stepSize: 500,
          color: '#00263A',
          font: {
            size: 16,
            weight: 'bold'
          }
        }
      },
      y: {
        display: false
      }
    }
  }
}
</script>

<template>
  <PageTitle :sub-page="true" title="Household Growth Over Time" subtitle="The increase or decrease in number of households over time is a good way to visualize growth trends in a trade area." />
  <section class="component-margin-y">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-12" :data="growthOverTimeData.households" description="Households" />
  </section>
  <div class="component-margin-y">
    <LineChartComponent :data="growthOverTimeChart.data" :options="growthOverTimeChart.options" :height="100"/>
  </div>
  <GrayBarData :data="growthOverTimeData">
    <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-2" :data="growthOverTimeData.fiveYrProjectedNumOfHouseholds" description="Number of Households 5 Year Forecast" />
    <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-2" :data="growthOverTimeData.fiveYrProjectedIncrease" description="Projected Increase (5 Years)" />
    <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-2" :data="growthOverTimeData.tenYrProjectedNumOfHouseholds" description="Number of Households 10-Year Forecast" />
    <ColumnData fontData="data-xs" fontDescription="data-title-sm" col="col-2" :data="growthOverTimeData.tenYrProjectedIncrease" description="Projected Increase (10 Years)" />
  </GrayBarData>
  <div class="component-margin-y">
    <h2 class="chart-title">Family Size</h2>
    <div v-if="pdfLayout">
      <BarChartComponent v-bind="{...familySizeChart}" :height="130" />
    </div>
    <div v-else>
      <BarChartComponent v-bind="{...familySizeChart}" :height="80" />
    </div>
  </div>
</template>

<style scoped>
</style>
