<script setup>
import coverPageMapBGPDF from '../assets/images/coverPageBGPDF.png'
import coverPageMapBGDesktop from '../assets/images/coverPageBGDesktop.png'
import fillerBrandLogo from '../assets/images/fillerBrandLogo.png'
import { defineProps } from 'vue'

defineProps({
  pdfLayout: Boolean,
  streetAddress: String,
  cityStateZip: String,
  name: String,
  email: String,
  date: String
})

const coverBackgroundPDF = coverPageMapBGPDF
const coverBackgroundDesktop = coverPageMapBGDesktop
// TODO: Filler brand logo needs to be setup dynamically for specific client
const brandLogo = fillerBrandLogo
</script>

<template>
  <div class="bg-no-repeat bg-center bg-cover suds-page-size flex flex-column justify-content-between" :class="{ 'pdf-width': pdfLayout, 'pdf-height': pdfLayout }"  :style="{backgroundImage: pdfLayout ? `url(${coverBackgroundPDF})` : `url(${coverBackgroundDesktop})`}">
    <div class="flex justify-content-center align-items-center h-full relative address">
      <p class="font-suds-bold suds-dk-blue text-xl line-height-2 text-center">
        {{ streetAddress }}<br />
        {{ cityStateZip }}
      </p>
    </div>
    <div class="align-self-end self justify-self-end flex align-items-end">
      <p class="suds-dk-grey mb-0">Prepared for</p>
      <img class="pdf-img" :src="brandLogo"  />
    </div>
  </div>
</template>

<style scoped>

.address {
  top: 100px;
}
</style>
