<script setup>
import AppDataTable from '@/components/AppDataTable.vue'
import PageTitle from '@/components/PageTitle.vue'
import RadiusMapComponent from '@/components/RadiusMapComponent.vue'
import { defineEmits, defineProps } from 'vue'
const props = defineProps({
  pdfLayout: Boolean
})

const pointsOfInterest = [
  { address: '3499 E Fairview Ave, Meridian, ID 83642', title: 'Albertsons Market' },
  { address: '4051 E Fairview Ave, Meridian, ID 83642', title: 'Walmart Super Center' },
  { address: '2350 N Eagle Rd, Meridian, ID 83646', title: 'Boise Co-Op' },
  { address: '3520 E Fairview Ave, Meridian, ID 83642', title: 'In-N-Out' },
  { address: '2150 N Eagle Rd, Meridian, ID 83646', title: 'Nike Factory' },
  { address: '2012 N Eagle Rd, Meridian, ID 83646', title: 'Chick-fil-A' }
]

const mainPointOfInterest = { address: '3540 E Longwing Ln Suite 220 Meridian, ID 83642', title: 'Suds Creative' }

const competitorTable = {
  tableColumns: [
    {
      field: 'name',
      header: 'Name'
    },
    {
      field: 'distance',
      header: 'Distance',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      name: 'Mister Car Wash',
      distance: '1.2 mi'
    },
    {
      name: 'Epic Shine',
      distance: '2.86 mi'
    },
    {
      name: "Joe Dirt's Express",
      distance: '2.43 mi'
    },
    {
      name: 'Quick Quack',
      distance: '1.74 mi'
    },
    {
      name: 'Hypershine Car Wash',
      distance: '2.14 mi'
    }
  ]
}
const emit = defineEmits(['nearby-retail-map-rendered'])
function nearbyRetailMapRendered () {
  emit('nearby-retail-map-rendered')
}
</script>

<template>
    <div>
      <PageTitle :subPage="true" title="Nearby Competitors (Within 3 Miles)" subtitle="Nearby competitors are express washes within a 3 mile radius of the site or in-bay automatic washes within 0.1 mile of the site. Competitors within these ranges have been shown to influence a new site, but anything further does not. Competitors are scored based on length of tunnel, brand, and newness of buildings." :pdf-layout=pdfLayout  />
      <div v-if="props.pdfLayout" class="component-margin-y">
        <RadiusMapComponent
          @map-rendered="nearbyRetailMapRendered"
          class="w-full mb-5"
          :height="300"
          :centerAddress="mainPointOfInterest.address"
          :zoom="11.60"
          :title="mainPointOfInterest.title"
          :radius="4828.03"
          :pointsOfInterest="pointsOfInterest"
        />
      </div>
      <div v-else class="component-margin-y">
        <RadiusMapComponent
          class="w-full mb-5"
          :height="500"
          :centerAddress="mainPointOfInterest.address"
          :zoom="12.35"
          :title="mainPointOfInterest.title"
          :radius="4828.03"
          :pointsOfInterest="pointsOfInterest"
        />
      </div>
      <app-data-table
        :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
        :columns="competitorTable.tableColumns"
        :title="competitorTable.title"
        :value="competitorTable.tableData"
      />
    </div>
</template>

<style scoped>

</style>
