<script setup>
import PageTitle from '@/components/PageTitle.vue'
import ColumnData from '@/components/ColumnData.vue'
import GrayBarData from '@/components/GrayBarData.vue'
import BarChartComponent from '@/components/BarChartComponent.vue'
import * as pattern from 'patternomaly'

const incomeData = {
  averageIncome: '$124,921',
  averageIncomePerPerson: '$49,159',
  medianWealth: '92,705.43'
}
const householdIncomeChart = {
  title: '',
  data: {
    labels: ['< $15K', '$15K - 25K', '$25K - 35K', '$35K - 50K', '$50K - 75K', '$75K - 100K', '$100K - 150K', '$150K - 175K', '$175K - 200K', '$200K - 250K', '$250K - 500K', '> $500K'],
    datasets: [
      {
        type: 'bar',
        label: 'Discretionary Income',
        backgroundColor: '#00263A',
        data: [2907, 2571, 3019, 4427, 8593, 8792, 13388, 4058, 2115, 5074, 1606, 1173],
        datalabels: {
          labels: {
            value: {
              display: false
            }
          }
        }
      },
      {
        type: 'bar',
        label: 'Household Income',
        backgroundColor: pattern.draw('diagonal', '#1be1f2'),
        data: [3907, 3571, 4019, 5427, 9593, 9792, 14388, 5058, 3115, 6074, 2606, 2173]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 120,
        right: 75
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        reverse: true,
        labels: {
          boxHeight: 20,
          boxWidth: 37,
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      },
      datalabels: {
        labels: {
          title: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -120,
            font: { size: 14, weight: 'bold' },
            formatter: (value, context) => context.datasetIndex === 0 ? context.chart.data.labels[context.dataIndex] : ''
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: (value, context) => {
              let sum = 0
              context.chart.config.data.datasets.forEach((dataPoint) => {
                sum += dataPoint.data[context.dataIndex]
              })
              return `${sum}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: true,
        title: {
          display: true,
          align: 'center',
          text: '# Of Households',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false
        },
        ticks: {
          stepSize: 2000,
          font: {
            size: 14,
            weight: 'bold'
          },
          color: '#00263A'
        }
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
</script>

<template>
  <PageTitle :sub-page="true" title="Household Income" subtitle="Household income and the breakdown of income groups in the trade area around the site are very important for determining how a site will perform. High discretionary income is one factor that commonly indicates successful sites." />
  <GrayBarData :data="incomeData" class="component-margin-y">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="incomeData.averageIncome" description="Street Traffic (Cars)*" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="incomeData.averageIncomePerPerson" description="Vehicles Per Household" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="incomeData.medianWealth" description="Vehicles in Trade Area*" />
  </GrayBarData>
  <div>
      <BarChartComponent v-bind="{...householdIncomeChart}" :height="200"/>
  </div>
</template>

<style scoped>
</style>
