<script setup>
</script>

<template>
  <div class="py-5 spinner-container">
    <img class="loader-logo" src="../assets/images/icons/suds-icon-spinner.png" alt="Suds Loading Spinner">
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  position: absolute;
  z-index: 1
}

@keyframes rotate {
  from { transform: rotate(0deg);   }
  to   { transform: rotate(360deg); }
}

.spinner {
  animation: rotate 1s linear infinite;
  background: #45C3D2;
  border-radius: 50%;
  height: 400px;
  width: 400px;
  position: relative;
}

.spinner::before,
.spinner::after {
  content: '';
  position: absolute;
}

.spinner::before {
  border-radius: 50%;
  background:
      linear-gradient(0deg,   hsla(0, 0%, 100%, 1  ) 50%, hsla(0, 0%, 100%, 0.9) 100%)   0   0,
      linear-gradient(90deg,  hsla(0, 0%, 100%, 0.9)  0%, hsla(0, 0%, 100%, 0.6) 100%) 100%   0,
      linear-gradient(180deg, hsla(0, 0%, 100%, 0.6)  0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
      linear-gradient(360deg, hsla(0, 0%, 100%, 0.3)  0%, hsla(0, 0%, 100%, 0  ) 100%)   0 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner::after {
  background: white;
  border-radius: 50%;
  top: 3%;
  bottom: 3%;
  left: 3%;
  right: 3%;
}
</style>
