<script setup>
import { defineProps, ref } from 'vue'
import BaseButton from '@/components/FormComponents/BaseButton.vue'
import SiteSelectReport from '@/views/SiteSelectReport'
defineProps({
  reportName: String
})

const selectedPage = ref()

const pages = ref([
  { name: 'Location Summary' },
  { name: 'Published' },
  { name: 'Needs Reviewed' },
  { name: 'Generating' },
  { name: 'Generated' },
  { name: 'Draft' }
])
</script>

<template>
  <h2 class="text-left py-6 pb-0">{{ reportName }}'s Report</h2>
  <div class="w-full flex justify-content-between py-6">
    <div>
      <DropDown name="filter-dropdown" v-model="selectedPage" filterMatchMode="contains" :options="pages" optionLabel="name" placeholder="Jump to Section" class="w-full">
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-items-center">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
        </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </DropDown>
    </div>
    <div>
      <BaseButton class="btn btn-secondary mx-3" icon="pi pi-file-export" text="Export"/>
      <BaseButton class="btn btn-secondary" icon="pi pi-share-alt" text="Share"/>
    </div>
  </div>
  <div>
    <SiteSelectReport :reportView="true"/>
  </div>
</template>
