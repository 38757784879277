<script setup>
import BarChartComponent from '@/components/BarChartComponent.vue'
import { defineProps } from 'vue'
import LineChartComponent from '@/components/LineChartComponent.vue'
import ColumnData from '@/components/ColumnData.vue'
import GrayBarData from '@/components/GrayBarData.vue'

defineProps({
  pdfLayout: Boolean
})

const educationLevelChart = {
  title: '',
  data: {
    labels: ['Elementary', 'High School', 'Some College/\nAssociates Degree', 'Bachelor’s Degree', 'Advanced Degree'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [988, 4110, 6019, 4249, 1908]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 200,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -200,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Population',
          padding: 5,
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false,
          drawBorder: false
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1000,
          callback: (v, i) => i + 'K',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        display: false,
        drawBorder: false
      }
    }
  }
}

const populationGrowthChart = {
  data: {
    type: 'line',
    labels: ['2019 Q4', '2020 Q1', '2020 Q2', '2020 Q3', '2020 Q4', '2021 Q1', '2021 Q2', '2021 Q3'],
    datasets: [{
      label: '',
      data: [27500, 27850, 27875, 27850, 27900, 27850, 27900, 27825],
      fill: false,
      borderColor: '#45C3D2',
      pointBackgroundColor: '#45C3D2',
      pointRadius: 5
    }]
  },
  options: {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          stepSize: 100,
          font: {
            weight: 'bold',
            size: 14
          }
        }
      }
    }
  }
}

const populationForecastData = {
  fiveYearPopForecast: '180,264',
  fiveYearProjectedIncrease: '0.12%',
  tenYearPopForecast: '181,908',
  tenYearProjectedIncrease: '2.71%'
}
</script>

<template>
  <div>
    <h2 class="chart-title">Education Level</h2>
    <div v-if="pdfLayout" class="component-margin-y">
      <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...educationLevelChart}" :height="110" />
    </div>
    <div v-else class="component-margin-y">
      <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...educationLevelChart}" :height="75" />
    </div>
  </div>
  <div class="component-margin-y">
    <h2 class="chart-title">Population Growth Over Time</h2>
    <LineChartComponent :data="populationGrowthChart.data" :options="populationGrowthChart.options" :height="100"/>
  </div>
  <GrayBarData :data="populationForecastData" class="component-margin-y">
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="populationForecastData.fiveYearPopForecast" description="5-Year Population Forecast" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="populationForecastData.fiveYearProjectedIncrease" description="Projected Increase (Over 5 Years)" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="populationForecastData.tenYearPopForecast" description="10-Year Population Forecast" />
    <ColumnData fontData="data-sm" fontDescription="data-title-sm" col="col-3" :data="populationForecastData.tenYearProjectedIncrease" description="Projected Increase (Over 10 Years)" />
  </GrayBarData>
</template>

<style scoped>
</style>
