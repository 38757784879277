import axios from 'axios'
import { store } from '../store/store'

export const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
})

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 419) {
      store.auth.logout()
    }
    return Promise.reject(error.response)
  }
)

export default {
  async login (payload) {
    await authClient.get('/sanctum/csrf-cookie').then(response => {
    })
    return authClient.post('/login', payload)
  },
  logout () {
    return authClient.post('/logout')
  },
  async forgotPassword (payload) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/forgot-password', payload)
  },
  getAuthUser () {
    return authClient.get('/api/users/auth')
  },
  async resetPassword (payload) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/reset-password', payload)
  },
  updatePassword (payload) {
    return authClient.put('/user/password', payload)
  },
  sendVerification (payload) {
    return authClient.post('/email/verification-notification', payload)
  },
  updateUser (payload) {
    return authClient.put('/user/profile-information', payload)
  }
}
