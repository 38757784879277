<script setup>
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { defineProps } from 'vue'

defineProps({
  title: {
    type: String,
    required: true
  },
  data: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const plugins = [ChartDataLabels]
</script>

<template>
  <h5>{{ title }}</h5>
  <ChartComponent type="pie" :data="data" :options="options" :plugins="plugins"/>
</template>

<style scoped>
</style>
