<script setup>
import BarChartComponent from '@/components/BarChartComponent.vue'
import * as pattern from 'patternomaly'
import PageTitle from '@/components/PageTitle.vue'
import { defineProps } from 'vue'

const props = defineProps({
  pdfLayout: Boolean
})

const getBarWidth = () => {
  if (props.pdfLayout) {
    return 25
  } else {
    return 35
  }
}

const violentCrimeChart = {
  title: '',
  data: {
    labels: ['Local', 'State', 'National'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: [pattern.draw('diagonal', '#45C3D2', 'rgba(255, 255, 255, 0.33)'), '#00263A', pattern.draw('diagonal-right-left', 'rgba(188, 188, 188, 0.89)', 'rgba(188, 188, 188, 1)')],
        data: [145, 76, 292]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: getBarWidth,
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 60,
        right: 50
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'left',
            offset: 0,
            font: { size: 14 },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    }
  }
}
const propertyCrimeChart = {
  title: '',
  data: {
    labels: ['Local', 'State', 'National'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: [pattern.draw('diagonal', '#45C3D2', 'rgba(255, 255, 255, 0.33)'), '#00263A', pattern.draw('diagonal-right-left', 'rgba(188, 188, 188, 0.89)', 'rgba(188, 188, 188, 1)')],
        data: [74, 158, 112]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: getBarWidth,
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 60,
        right: 50
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'left',
            offset: 0,
            font: { size: 14 },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    }
  }
}
const overallCrimeChart = {
  title: '',
  data: {
    labels: ['Local', 'State', 'National'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: [pattern.draw('diagonal', '#45C3D2', 'rgba(255, 255, 255, 0.33)'), '#00263A', pattern.draw('diagonal-right-left', 'rgba(188, 188, 188, 0.89)', 'rgba(188, 188, 188, 1)')],
        data: [66, 189, 134]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: getBarWidth,
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 60,
        right: 50
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'left',
            offset: 0,
            font: { size: 14 },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    }
  }
}
</script>

<template>
  <PageTitle :sub-page="true" title="Risk Assessment" subtitle="Although we hope this information is not ever necessary, there is always risk to any building on any site. It is valuable to consider the risks inherent at a site due to natural disasters and crime, as well as how near emergency services are to the site." />
      <div>
        <h2 class="component-margin-y">Crime</h2>
        <h3 class="component-margin-y">Violent Crime Averages</h3>
        <BarChartComponent v-bind="{...violentCrimeChart}" :height="props.pdfLayout ? 65 : 45"/>
        <h3 class="component-margin-y">Property Crime Averages</h3>
        <BarChartComponent v-bind="{...propertyCrimeChart}" :height="props.pdfLayout ? 65 : 45"/>
        <h3 class="component-margin-y">Overall Crime Averages</h3>
        <BarChartComponent v-bind="{...overallCrimeChart}" :height="props.pdfLayout ? 65 : 45"/>
      </div>
</template>

<style scoped>
.emergencyServicesTable {
  border: solid white 2px;
  border-radius: 5px;
  overflow: hidden;
}
</style>
