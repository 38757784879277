import { reactive } from 'vue'
import { getError } from '@/utils/helpers'
import router from '@/router'
import AuthService from '@/services/AuthService'

export const auth = reactive({
  user: null,
  loading: false,
  loggedout: null,
  error: null,
  userRole: [],
  getters: {
    authUser () {
      return auth.user
    },
    authUserRole () {
      return auth.userRole
    },
    isAdmin () {
      return auth.userRole.includes('admin')
    },
    error () {
      return auth.error
    },
    loading () {
      return auth.loading
    },
    loggedIn () {
      return !!auth.user
    }
  },
  logout () {
    return AuthService.logout()
      .then(() => {
        this.user = null
        this.loggedout = true
        router.push({
          path: '/login'
        })
      })
      .catch((error) => {
        this.error = getError(error)
      })
  },
  getAuthUser () {
    this.loading = true
    return AuthService.getAuthUser()
      .then((response) => {
        this.user = response.data.data
        response.data.data.userRole.forEach((role) => this.userRole.push(role.name))
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.user = null
        this.userRole = []
        this.error = getError(error)
      })
  }
})
