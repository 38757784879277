<script setup>
import BarChartComponent from '@/components/BarChartComponent.vue'
import { defineProps } from 'vue'
import AppDataTable from '@/components/AppDataTable.vue'

defineProps({
  pdfLayout: Boolean
})

const ethnicityChart = {
  title: '',
  data: {
    labels: ['White', 'Black', 'Asian', 'Hispanic', 'Other'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [99408, 2635, 28149, 38272, 8563]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 200,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -200,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 16
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Population',
          padding: 5,
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false,
          drawBorder: false
        },
        beginAtZero: true,
        ticks: {
          stepSize: 10000,
          callback: (v, i) => i * 10 + 'K',
          font: {
            weight: 'bold',
            size: 16
          },
          color: '#616161'
        }
      },
      y: {
        display: false,
        drawBorder: false
      }
    }
  }
}

const medianIncomeByEthnicityChart = {
  title: '',
  data: {
    labels: ['White', 'Black', 'Asian', 'Hispanic', 'Other'],
    datasets: [
      {
        type: 'bar',
        label: '',
        backgroundColor: '#45C3D2',
        data: [102200, 89000, 95300, 5427, 9593]
      }
    ]
  },
  options: {
    indexAxis: 'y',
    barThickness: 'flex',
    borderRadius: 5,
    barPercentage: 0.8,
    layout: {
      padding: {
        left: 200,
        right: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        labels: {
          name: {
            color: '#616161',
            anchor: 'start',
            align: 'right',
            offset: -200,
            font: { size: 14, weight: 'bold' },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex]
            }
          },
          value: {
            color: '#616161',
            anchor: 'end',
            align: 'end',
            offset: 20,
            font: {
              weight: 'bold',
              size: 14
            },
            formatter: function (value) {
              return `${value}`
            }
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          align: 'center',
          text: 'Income',
          padding: 5,
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        },
        grid: {
          display: false,
          drawBorder: false
        },
        beginAtZero: true,
        ticks: {
          stepSize: 20000,
          callback: (v, i) => i * 20 + 'K',
          font: {
            weight: 'bold',
            size: 14
          },
          color: '#00263A'
        }
      },
      y: {
        display: false,
        drawBorder: false
      }
    }
  }
}

const languageSpokenTable = {
  tableColumns: [
    {
      field: 'spoken',
      header: 'Language Spoken'
    },
    {
      field: 'population',
      header: 'Population'
    },
    {
      field: 'percentage',
      header: 'Percentage',
      style: 'column-right-align'
    }
  ],
  tableData: [
    {
      spoken: 'English',
      population: '',
      percentage: ''
    },
    {
      spoken: 'Spanish',
      population: '',
      percentage: ''
    },
    {
      spoken: 'Chinese',
      population: '',
      percentage: ''
    },
    {
      spoken: 'French',
      population: '',
      percentage: ''
    },
    {
      spoken: 'German',
      population: '',
      percentage: ''
    },
    {
      spoken: 'Other',
      population: '',
      percentage: ''
    }
  ]
}
</script>

<template>
  <section>
    <h2 class="chart-title">Ethnicity</h2>
    <div v-if="pdfLayout" class="component-margin-y">
      <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...ethnicityChart}" :height="110" />
    </div>
    <div v-else class="component-margin-y">
      <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...ethnicityChart}" :height="75" />
    </div>
  </section>
  <section>
    <h2 class="chart-title">Median Income by Ethnicity</h2>
    <div v-if="pdfLayout" class="component-margin-y">
      <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...medianIncomeByEthnicityChart}" :height="110" />
    </div>
    <div v-else class="component-margin-y">
      <BarChartComponent :pdfLayout="pdfLayout" v-bind="{...medianIncomeByEthnicityChart}" :height="75" />
    </div>
  </section>
  <div>
    <h2 class="chart-title">
      Language Spoken
    </h2>
    <app-data-table :class="{ 'text-xs': pdfLayout, 'text-xl': !pdfLayout }"
                    :columns="languageSpokenTable.tableColumns"
                    :value="languageSpokenTable.tableData"
    />
  </div>
</template>

<style scoped>
</style>
