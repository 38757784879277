<script setup>
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { defineProps } from 'vue'

defineProps({
  data: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  },
  height: {
    type: Number,
    required: true
  }
})

const plugins = [ChartDataLabels]
</script>

<template>
    <ChartComponent type="bar" :data="data" :options="options" :plugins="plugins" :height="height"/>
</template>

<style scoped>
</style>
