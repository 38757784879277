<script setup>
import BatteryChartComponent from '@/components/BatteryChartComponent.vue'
import PageTitle from '@/components/PageTitle.vue'
import ColumnData from '@/components/ColumnData.vue'
import { defineProps } from 'vue'

const props = defineProps({
  pdfLayout: Boolean,
  projectedRevenue: String,
  projectedMembers: String,
  streetTraffic: String
})

const ingressEgress = {
  title: 'Ingress/Egress',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [50]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [50]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const overallTraffic = {
  title: 'Overall Traffic',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [100]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [0]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const populationGrowth = {
  title: 'Population Growth',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [75]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [25]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const populationDensity = {
  title: 'Population Density',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [75]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [25]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const visibility = {
  title: 'Visibility',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [75]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [25]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const retail = {
  title: 'High Frequency Retail*',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [50]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [50]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const density = {
  title: 'Competition Density*',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [25]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [75]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
const locationSummaryData = {
  projectedRevenue: (num) => { return num.toLocaleString() },
  projectedMembers: (num) => { return num.toLocaleString() },
  streetTraffic: (num) => { return num.toLocaleString() }
}
const compProximity = {
  title: 'Competition* Proximity',
  height: 40,
  pdfLayout: props.pdfLayout,
  data: {
    labels: [''],
    datasets: [
      {
        backgroundColor: '#45C3D2',
        borderSkipped: false,
        data: [25]
      },
      {
        backgroundColor: '#D9D9D9',
        data: [75]
      }
    ]
  },
  options: {
    borderWidth: 1,
    borderColor: '#000',
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        labels: {
          value: {
            display: false
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    }
  }
}
</script>

<template>
  <PageTitle :icon="require('../../assets/images/LocationSummaryIcon.png')" title="Location Summary" subtitle="The location summary shows projected revenue and members at site maturity, as well as key metrics that are considered important in determining the potential success of a car wash at the given site." :pdf-layout=pdfLayout />
  <p class="font-semibold black mt-2" :class="pdfLayout ? 'text-xs' : 'text-xl'">Projections (at Maturity*):</p>
  <section class="flex text-xl justify-content-between component-margin-y">
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="locationSummaryData.projectedRevenue(1300000)" description="Projected Revenue" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="locationSummaryData.projectedMembers(1300000)" description="Projected Members" />
    <ColumnData fontData="data-lg" fontDescription="data-title-lg" col="col-3" :data="locationSummaryData.streetTraffic(108503)" description="Street Traffic (Cars)*" />
  </section>
  <div class="flex justify-content-between">
    <div class="mt-3 location-chart-padding-left">
        <BatteryChartComponent v-bind="{...ingressEgress}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
    <div class="mt-3 location-chart-padding-right">
      <BatteryChartComponent v-bind="{...overallTraffic}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
  </div>
  <div class="flex justify-content-between">
    <div class="location-chart-padding-left">
      <BatteryChartComponent v-bind="{...populationGrowth}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
    <div class="location-chart-padding-right">
      <BatteryChartComponent v-bind="{...populationDensity}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
  </div>
  <div class="flex justify-content-between">
    <div class="location-chart-padding-left">
      <BatteryChartComponent v-bind="{...compProximity}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
    <div class="location-chart-padding-right">
      <BatteryChartComponent v-bind="{...visibility}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
  </div>
  <div class="flex justify-content-between">
    <div class="location-chart-padding-left">
      <BatteryChartComponent v-bind="{...retail}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
    <div class="location-chart-padding-right">
      <BatteryChartComponent v-bind="{...density}" />
      <div class="flex justify-content-between data-title-lg">
        <span class="w-full">Suboptimal</span>
        <span class="w-full text-right">Optimal</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
