import { reactive } from 'vue'
import UserService from '@/services/UserService'
import { getError } from '@/utils/helpers'

export const user = reactive({
  user: null,
  users: [],
  loading: false,
  error: null,
  getters: {
    user () {
      return user.user
    },
    users () {
      return user.users
    },
    loading () {
      return user.loading
    },
    error () {
      return user.error
    }
  },
  fetchUser (userId) {
    this.loading = true
    return UserService.fetchUser(userId)
      .then((response) => {
        this.user = response.data.data
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.error = getError(error)
      })
  },
  fetchUsers () {
    this.loading = true
    return UserService.fetchUsers()
      .then((response) => {
        this.users = response.data.data
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.error = getError(error)
      })
  }
})
