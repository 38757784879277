import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Chart from 'primevue/chart'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import TreeSelect from 'primevue/treeselect'
import Paginator from 'primevue/paginator'
import RadioButton from 'primevue/radiobutton'
import InputNumber from 'primevue/inputnumber'

import ChartDataLabels from 'chartjs-plugin-datalabels'

const app = createApp(App).use(router)
app.use(PrimeVue)
app.use(ChartDataLabels)
app.component('DialogComponent', Dialog)
app.component('ButtonComponent', Button)
app.component('ChartComponent', Chart)
app.component('CardComponent', Card)
app.component('DataTableComponent', DataTable)
app.component('ColumnComponent', Column)
app.component('InputText', InputText)
app.component('InputPassword', Password)
app.component('DropDown', Dropdown)
app.component('Check-box', Checkbox)
app.component('IconField', IconField)
app.component('InputIcon', InputIcon)
app.component('TreeSelect', TreeSelect)
app.component('PaginatorComponent', Paginator)
app.component('RadioButton', RadioButton)
app.component('InputNumber', InputNumber)
app.mount('#app')
