<script setup>
import { defineEmits, defineProps } from 'vue'
import BaseSearch from '@/components/FormComponents/BaseSearch.vue'
import BaseFilterDropDown from '@/components/FormComponents/BaseFilterDropDown.vue'
import BaseCheckbox from '@/components/FormComponents/BaseCheckbox.vue'
import BaseButton from '@/components/FormComponents/BaseButton.vue'

defineProps({
  external: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['search-change', 'filter-change', 'disabled-reports-visibility'])

const handleSearchChange = (value) => {
  emit('search-change', value)
}

const handleFilterChange = (value) => {
  emit('filter-change', value)
}

const toggleDisabledVisibility = (value) => {
  emit('disabled-reports-visibility', value)
}
</script>

<template>
  <h2 class="text-left py-6">Report Dashboard</h2>
  <section class="flex align-items-center" :class="{'justify-content-between' : !external, 'justify-content-end' : external}">
    <router-link v-if="!external" class="no-underline" :to="{ name: 'DashboardInputFormView' }">
      <BaseButton btn-class="btn-primary flex justify-content-center" icon="pi pi-plus" text="Create New Siteselect Report" />
    </router-link>
    <div class="flex align-items-center w-8 justify-content-end">
      <div v-if="!external" class="col-4 flex align-items-center justify-content-end">
        <BaseCheckbox @disabled-reports-visibility="toggleDisabledVisibility"/>
      </div>
      <div v-if="!external" class="col-2">
        <BaseFilterDropDown @filter-change="handleFilterChange"/>
      </div>
      <div class="col-4">
        <BaseSearch @search-change="handleSearchChange" />
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
