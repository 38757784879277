<script setup>
import { ref, defineProps } from 'vue'

defineProps({
  userAdmin: {
    type: Boolean,
    default: true
  }
})

const selectedUserRole = ref('')

const userRoleOptions = ref([
  { name: 'Admin' },
  { name: 'Internal' },
  { name: 'External' }
])
</script>

<template>
  <div>
    <DropDown name="filter-dropdown" v-model="selectedUserRole" filterMatchMode="contains" :options="userRoleOptions" :placeholder="userAdmin ? 'Admin' : 'Internal'" optionLabel="name" class="w-full">
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex align-items-center">
          <div>{{ slotProps.value.name }}</div>
        </div>
        <span v-else>
            {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="flex align-items-center">
          <div>{{ slotProps.option.name }}</div>
        </div>
      </template>
    </DropDown>
  </div>
</template>

<style>
</style>
