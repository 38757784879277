<script setup>
import { ref, defineEmits } from 'vue'

const selectedItem = ref()

const filterOptions = ref([
  { name: 'Client View Date' },
  { name: 'Published' },
  { name: 'Needs Reviewed' },
  { name: 'Generating' },
  { name: 'Generated' },
  { name: 'Draft' }
])

const emit = defineEmits(['filter-change'])

const handleFilterChange = () => {
  emit('filter-change', selectedItem.value.name)
}
</script>

<template>
  <DropDown name="filter-dropdown" v-model="selectedItem" filterMatchMode="contains" :options="filterOptions" optionLabel="name" placeholder="Client View Date" class="w-full" @change="handleFilterChange">
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <div>{{ slotProps.value.name }}</div>
      </div>
      <span v-else>
          {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center">
        <div>{{ slotProps.option.name }}</div>
      </div>
    </template>
  </DropDown>
</template>

<style >
.p-dropdown-label {
  text-align: left;
}
.p-dropdown {
  border-radius: 6px;
  border: 2.5px solid #E5E5E5;
}
.p-dropdown:hover,  .p-dropdown:focus{
  border: 2.5px solid #47E1F1;
}
.p-dropdown-panel .p-dropdown-items .p-focus{
  background: #9FF6FF;
}
.p-icon{
  color: #D4D4D4;
}
.p-placeholder{
  color: #838383;
}
</style>
