<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['search-change'])

const handleSearchChange = (event) => {
  emit('search-change', event.target.value)
}
</script>

<template>
  <IconField iconPosition="right">
    <InputText class="w-full" placeholder="Search"  @input="handleSearchChange"/>
    <InputIcon>
      <i class="pi pi-search" />
    </InputIcon>
  </IconField>
</template>

<style scoped>
.p-inputtext {
  border-radius: 6px;
  border: 2.5px solid #E5E5E5;
}
.pi {
  color: #C9C9C9;
}
</style>
