import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import DashboardInputFormView from '@/views/DashboardInputFormView.vue'
import NewUserFormView from '@/views/NewUserFormView.vue'
import ReportView from '@/views/ReportView.vue'
import middlewarePipeline from '@/router/middlewarePipeline'
import auth from '@/middleware/auth'
import admin from '@/middleware/admin'
import { store } from '@/store/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/SiteSelectReport.vue')
  },
  {
    path: '/nearby-retail',
    name: 'nearby-retail',
    component: () => import(/* webpackChunkName: "nearby-retail" */ '../views/LocationSummaryPages/NearbyRetailView.vue')
  },
  {
    path: '/high-traffic-retail',
    name: 'high-traffic-retail',
    component: () => import(/* webpackChunkName: "high-traffic-retail" */ '../views/LocationSummaryPages/HighTrafficRetailView.vue')
  },
  {
    path: '/chart-test',
    name: 'chart-test',
    component: () => import(/* webpackChunkName: "graph-test" */ '../views/ChartTest.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { middleware: [auth] },
    props: { external: false },
    component: () => import(/* webpackChunkName: "dashboard layout" */ '../layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        props: { external: false },
        name: 'DashboardView',
        component: DashboardView
      },
      {
        path: '/form',
        name: 'DashboardInputFormView',
        component: DashboardInputFormView
      },
      {
        path: '/report',
        name: 'ReportView',
        component: ReportView,
        props: true
      },
      {
        path: '/manage-users',
        name: 'UserManagementView',
        component: () => import(/* webpackChunkName: "user management view" */ '../views/UserManagementView.vue'),
        meta: { middleware: [auth, admin] }
      },
      {
        path: '/create-user',
        name: 'NewUserFormView',
        component: NewUserFormView,
        meta: { middleware: [auth, admin] }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/pages/LoginView')
  },
  {
    path: '/map-test',
    name: 'map-test',
    component: () => import(/* webpackChunkName: "map-test" */ '../views/MapTest.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware
  const context = { to, from, next, store }

  if (!middleware) {
    return next()
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export { routes }
export default router
